.chat-bubble-container {
  display: flex;
  padding: 16px 0;

  .avatar {
    margin: 0 30px 0 40px;
    display: flex;

    img {
      height: 52px;
      width: 52px;
    }
  }

  .chat-message {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 16px;
    border-radius: 5px;
    border-top-left-radius: 0;
    background-color: #FBFAFA;
  }

  &.user .chat-message {
    background-color: #322BAA;
    border-color: #322BAA;
    color: white;
    font-weight: 600;
    font-size: 18px;
  }
}