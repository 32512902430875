.app {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 46px 44px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.button {
  background-color: #322baa;
  color: white;
  font-size: 22px;
  font-weight: 600;
  padding: 0 40px;
  height: 50px;
  border: none;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #4943b7;
  }

  &:disabled {
    background: #e2e8f0;
    color: #fff;
    cursor: default;
  }

  &.secondary {
    background-color: white;
    color: #322baa;
    border: none;
    outline: 2px solid #322baa;
    font-size: 20px;
    padding: 0 16px;
    height: 54px;

    &:hover {
      color: #4943b7;
      outline-color: #4943b7;
    }

    &:focus,
    &:focus-visible {
      color: white;
      background-color: #4943b7;
      outline-color: #4943b7;
      outline-offset: 2px;
    }

    &:active {
      color: #1c1e84;
      background-color: white;
      outline-color: #1c1e84;
      outline-offset: 0;
    }

    &:disabled {
      background-color: #fff;
      color: #e2e8f0;
      outline-color: #e2e8f0;
    }
  }
}

.container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: white;
}

label {
  color: #2d3748;
  margin-bottom: 2px;
  font-weight: 600;
}

.text-input {
  height: 50px;
  padding: 0 16px;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 324px;
  font-size: 18px;

  &:focus-visible {
    outline-color: #322baa;
  }
}

h1 {
  font-family: "League Gothic";
  color: #322baa;
  font-weight: 400;
  font-size: 64px;
}

h3 {
  font-size: 22px;
  margin-bottom: 8px;
}

p {
  max-width: 870px;
  font-size: 18px;
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}

a {
  font-weight: 600;
  color: #322baa;
}
