.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 910px;
  padding: 30px;

  h1 {
    margin: 32px 0 8px;
    font-size: 80px;
  }

  .form {
    margin-top: 24px;
  }
}

.error-container {
  display: none;
  color: red;
  margin-top: 0;

  &.visible {
    display: block;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.button-container {
  text-align: center;
}