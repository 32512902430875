.suggestions-container {
  max-width: 870px;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex: 1;

  .chat-issues {
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-top: 16px;
    gap: 12px;
  }

  .suggestion-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 32px;
    margin-top: 32px;
    padding-bottom: 5px;
  }
}