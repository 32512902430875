.header-container {
  display: flex;
  padding: 0;

  h1 {
    margin: 0;
  }

  img {
    margin-right: 8px;
  }
}