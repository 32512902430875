.chat-container {
  max-width: 1304px;
  height: calc(100vh - 92px);
  display: flex;
  flex-direction: column;
  flex: 1;

  .chat-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px 0;

    .functions {
      display: flex;
      gap: 8px;
    }
  }

  .chat-inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100% - 93px);

    .scroll-container {
      display: flex;
      max-height: calc(100% - 180px);
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;

      .scroll-inner {
        overflow-y: auto;

        &.scroll-bottom {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
    .question-container {
      border-top: 1px solid #322baa;
      padding: 32px 32px;
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-top: 8px;

      form {
        display: flex;

        .text-input {
          flex-grow: 1;
        }
      }

      p.chat-info {
        margin-bottom: 0;
        align-self: center;
      }
    }
  }
}
