@font-face {
  font-family: "Source Sans Pro";
  src: local("SourceSansPro"),
    url("./fonts/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Source Sans Pro";
  src: local("SourceSansPro"),
    url("./fonts/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Source Sans Pro";
  src: local("SourceSansPro"),
    url("./fonts/SourceSansPro-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "League Gothic";
  src: local("LeagueGothic"),
    url("./fonts/LeagueGothic-Regular.ttf") format("truetype");
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
select,
textarea,
button {
  font-family: inherit;
}
